@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("//db.onlinewebfonts.com/c/428d71255580adb4952b65f79e79b36a?family=Benegraphic");
@import url("//db.onlinewebfonts.com/c/4a370a202b44b2940355d9253d0051ab?family=QuigleyWiggly");
@import url("https://fonts.googleapis.com/css2?family=Passion+One&display=swap");

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
